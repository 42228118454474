<template>
  <div>
    <v-row
      justify="center"
      class="mt-4"
      v-if="
        !publicdata.loading && publicdata.wettkampf.beginn.datum > timer.datum
      "
    >
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="auto" class="text-center" align-self="center">
            <v-img width="50px" :src="publicdata.verein.verein.logo" />
          </v-col>
          <v-col cols="auto" align-self="center">
            <h3 class="white--text">{{ publicdata.verein.verein.name }}</h3>
            <h2>{{ publicdata.wettkampf.name }}</h2>
          </v-col>
          <v-col cols="12" class="text-center mb-0">
            <h2>
              VORANMELDUNG ZUM WETTKAMPF
            </h2>
          </v-col>
          <v-col cols="12" md="10" lg="8" class="mt-0">
            <v-card
              class="my-3 pa-3 rounded-xl"
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              link
              @click="erfassung('einzel')"
              :loading="loading"
            >
              <v-row justify="center">
                <v-col cols="auto" class="ml-2" align-self="center">
                  <v-icon :color="template.colors.block_text" large>
                    mdi-card-account-details
                  </v-icon>
                </v-col>

                <v-col align-self="center">
                  <h3>
                    Einzelanmeldung
                  </h3>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              class="my-3 pa-3 rounded-xl"
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              link
              @click="erfassung('team')"
            >
              <v-row justify="center">
                <v-col cols="auto" class="ml-2" align-self="center">
                  <v-icon :color="template.colors.block_text" large>
                    mdi-account-group
                  </v-icon>
                </v-col>

                <v-col align-self="center">
                  <h3>
                    Teamanmeldung
                  </h3>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-4"
      v-if="
        !publicdata.loading &&
        publicdata.wettkampf.beginn.datum <= timer.datum &&
        publicdata.wettkampf.ende.datum >= timer.datum
      "
    >
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="auto" class="text-center" align-self="center">
            <v-img width="50px" :src="publicdata.verein.verein.logo" />
          </v-col>
          <v-col cols="auto" align-self="center">
            <h3 class="white--text">{{ publicdata.verein.verein.name }}</h3>
            <h2>{{ publicdata.wettkampf.name }}</h2>
          </v-col>
          <v-col cols="12" class="text-center mb-0">
            <h2>
              Sportler CheckIn
            </h2>
          </v-col>
          <v-col cols="12" md="10" lg="8" class="mt-0">
            <v-card
              class="my-3 pa-3 rounded-xl"
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              link
              @click="erfassung('einzel')"
              :loading="loading"
            >
              <v-row justify="center">
                <v-col cols="auto" class="ml-2" align-self="center">
                  <v-icon :color="template.colors.block_text" large>
                    mdi-card-account-details
                  </v-icon>
                </v-col>

                <v-col align-self="center">
                  <h3>
                    Jetzt Anwesenheit erfassen
                  </h3>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-4"
      v-if="!publicdata.verein && !publicdata.wettkampf"
    >
      <v-col cols="12" class="text-center mb-4" v-if="!publicdata.nodata">
        <h2>Wettkampf wird geladen</h2>
        <v-progress-circular
          :color="template.colors.primary"
          circle
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col cols="12" class="text-center mb-4" v-if="publicdata.nodata">
        <h2>Seite nicht gefunden</h2>
        <p>
          Dein eingegebener Link scheint fehlerhaft zu sein. Bitte überprüfe
          deine Eingabe und versuche es erneut.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../store'
//import firebase, { firestore } from 'firebase'
import router from '../../routes/index'

export default {
  name: 'Mein-Verein',
  data() {
    return {
      newzuschauer: false,
      zuschauerlogin: false,
      loading: false,
      veranstaltung: '',
      thisday: true,
    }
  },
  computed: {
    ...mapGetters({
      publicdata: 'publicdata',
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  mounted() {
    if (this.$route.params.verein && this.$route.params.veranstaltung) {
      if (!this.publicdata.wettkampf) {
        store.dispatch('setWettkampf', {
          name: this.$route.params.verein,
          veranstaltung: this.$route.params.veranstaltung,
        })
      }
    }
  },
  methods: {
    erfassung(art) {
      this.loading = true
      if (!this.user.data) {
        firebase
          .auth()
          .setPersistence(firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            // New sign-in will be persisted with session persistence.
            return firebase.auth().signInAnonymously()
          })
          .then(() => {
            this.loading = false
            if (art == 'einzel') {
              router.push(
                '/wk/' +
                  this.publicdata.verein.alias +
                  '/' +
                  this.publicdata.wettkampf.id +
                  '/einzelperson',
              )
            } else {
              router.push(
                '/wk/' +
                  this.publicdata.verein.alias +
                  '/' +
                  this.publicdata.wettkampf.id +
                  '/team',
              )
            }
          })
          .catch(function (error) {
            this.loading = false
            console.log(error)
          })
      } else {
        this.loading = false
        if (art == 'einzel') {
          router.push(
            '/wk/' +
              this.publicdata.verein.alias +
              '/' +
              this.publicdata.wettkampf.id +
              '/einzelperson',
          )
        } else {
          router.push(
            '/wk/' +
              this.publicdata.verein.alias +
              '/' +
              this.publicdata.wettkampf.id +
              '/team',
          )
        }
      }
    },
  },
}
</script>
